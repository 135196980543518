import React, { useState, useEffect } from 'react';

import { getFirestore } from 'firebase/firestore';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../../firebase';

const FormularioSuggestions = () => {
  const [titulo, setTitulo] = useState('');
  const [imagen, setImagen] = useState(null);
  const [ingredientes, setIngredientes] = useState(['']);
  const [elaboracion, setElaboracion] = useState(['']);
  const [categorias, setCategorias] = useState([]);
  const [cantidadPersonas, setCantidadPersonas] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [progress] = useState(0);


  // Función para manejar la subida de imágenes
  const handleImageUpload = async (e) => {
    const image = e.target.files[0];

    try {
      const storage = getStorage(app);
      const storageRef = ref(storage, `images/${image.name}`);

      // Subir la imagen al almacenamiento
      await uploadBytes(storageRef, image);

      // Obtener la URL de descarga de la imagen
      const imageUrl = await getDownloadURL(storageRef);

      // Establecer la URL de la imagen en tu estado
      setImagen(imageUrl);
    } catch (error) {
      console.error('Error al subir la imagen: ', error);
    }
  };

  // Función para agregar un nuevo ingrediente al array de ingredientes
  const agregarIngrediente = () => {
    setIngredientes([...ingredientes, '']);
  };

  // Función para actualizar un ingrediente específico en el array de ingredientes
  const actualizarIngrediente = (index, value) => {
    const nuevosIngredientes = [...ingredientes];
    nuevosIngredientes[index] = value;
    setIngredientes(nuevosIngredientes);
  };

  // Función para eliminar un ingrediente del array de ingredientes
  const eliminarIngrediente = (index) => {
    const nuevosIngredientes = [...ingredientes];
    nuevosIngredientes.splice(index, 1);
    setIngredientes(nuevosIngredientes);
  };

  // Función para agregar un nuevo paso de elaboración al array de elaboración
 

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Guardar los datos en Firestore
    try {

      const db = getFirestore();
      const docRef = doc(db, 'recetas', titulo);

      const recetaData = {
        titulo: titulo,
        imagen: imagen,
        ingredientes: ingredientes,
        elaboracion: elaboracion,
        categorias: selectedCategories,
        cantidadPersonas: cantidadPersonas,
        descripcion: descripcion,
      };

      // Establecer los datos de la receta en el documento
      await setDoc(docRef, recetaData);
      console.log('Receta enviada con ID: ', docRef.id);

      // Restablecer los campos del formulario
      setTitulo('');
      setImagen(null);
      setIngredientes(['']);
      setElaboracion(['']);
      setSelectedCategories([]);
      setCantidadPersonas('');
      setDescripcion('');
    } catch (error) {
      console.error('Error al enviar la receta: ', error);
    }
  };

  useEffect(() => {

    const fetchCategorias = async () => {
      const db = getFirestore(app);
      try {
        const categoriasSnapshot = await getDocs(collection(db, 'etiquetas'));
        const categoriasData = categoriasSnapshot.docs.map((doc) => doc.data());
        setCategorias(categoriasData);
      } catch (error) {
        console.error('Error al obtener categorías:', error);
      }
    };

    fetchCategorias();
  }, []);

  return (
    <div className='max-w-screen-md mx-auto p-5'>
      <h2 className='mb-5 text-2xl'>Formulario de Receta</h2>
      <form onSubmit={handleSubmit}>
        <div >
          <label className='block font-bold mt-3'>Título</label>
          <input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} className='w-full p-2 mt-1 border border-solid rounded' />
        </div>
        <div>
          <label className='block font-bold mt-3'>Imagen</label>
          <input type="file" accept="image/*" onChange={handleImageUpload} className='w-full p-2 mt-2 border border-solid rounded ' />
          {imagen && <img src={imagen} alt="Vista previa de la imagen" style={{ width: '100px' }} className='block mt-2' />}
          {progress > 0 && <progress value={progress} max="100" className='w-full h-5 mt-2' />}
        </div>
        <div>
          <label className='block font-bold mt-3'>Cantidad de Personas</label>
          <input type="number" value={cantidadPersonas} onChange={(e) => setCantidadPersonas(e.target.value)} className='w-full p-2 mt-1 border border-solid rounded' />
        </div>
        <div>
          <label className='block font-bold mt-3'>Descripción</label>
          <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} className='w-full p-2 mt-1 border border-solid rounded' />
        </div>
        <div>
          <label className='block font-bold mt-3'>Ingredientes</label>
          {ingredientes.map((ingrediente, index) => (
            <div key={index}>
              <input
                className='w-full p-2 mt-1 border border-solid rounded'
                type="text"
                value={ingrediente}
                onChange={(e) => actualizarIngrediente(index, e.target.value)}
              />
              <button type="button" onClick={() => eliminarIngrediente(index)} className='bg-red-500 text-white p-1 py-2 border-none rounded cursor-pointer mt-2'>
                Eliminar
              </button>
            </div>
          ))}
          <button type="button" onClick={agregarIngrediente} className='bg-blue-500 text-white p-1 py-2 border-none rounded cursor-pointer mt-2'>
            Añadir Ingrediente
          </button>
        </div>
        
        <div>
          <label className='block font-bold mt-3'>Categorías</label>
          <select
            className='w-full p-2 border border-solid rounded mt-1 h-[100]'
            multiple
            value={selectedCategories}
            onChange={(e) => setSelectedCategories(Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {categorias.map((categoria) => (
              <option key={categoria.nombre} value={categoria.nombre} className='bg-white p-1 py-2 border border-solid rounded mt-1 checked:bg-blue-500 checked:text-white'>
                {categoria.nombre}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className='bg-green-500 text-white p-1 py-2 border-none rounded cursor-pointer mt-5'>Enviar Receta</button>
      </form>
    </div>
  );
};

export default FormularioSuggestions;
