import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { auth, signInWithEmailAndPassword, onAuthStateChanged } from '../../firebase';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (email) => {
      if (email) {
        setEmail(email);
        navigate('/');
      }
    });

    return unsubscribe;
  }, [navigate]);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const email = userCredential.email;
        setEmail(email);
        navigate('/');
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="bg-no-repeat bg-contain  flex justify-center items-center h-screen" style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/menuvirtual-b639f.appspot.com/o/images%2Ffondo.png?alt=media&token=37e50bf0-d2a6-4df4-ac66-8380dfd0c425&_gl=1*19aicgc*_ga*NzYxMjczMDk0LjE2OTQ5ODkxMDQ.*_ga_CW55HF8NVT*MTY5ODY5MzI4Ny4xMS4xLjE2OTg2OTMzMjQuMjMuMC4w')" }}>
      <div className="max-w-md w-full rounded-md p-4 bg-gray-800/25 backdrop-blur-md">
        <h1 className="text-center text-3xl font-bold text-blue-500 mb-6">Login</h1>
        <label for="email" className='text-white'>Email</label>
        <input
          className="w-full p-2 rounded-md"
          type="text"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label for="password" className='text-white'>Password</label>
        <input
          className="w-full p-2 rounded-md"

          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className=" bg-blue-500 text-white py-2 rounded-md mt-4 w-full" onClick={handleLogin}>Login</button>
        <p className=" text-zinc-50 mt-6 ml-6 text-center">Or, login with ...</p>
        <div className=" flex justify-between mt-6">
          <button id="google-button" className="border-2 border-gray-300 rounded-full w-12 h-12 bg-gray-50  py-3 cursor-pointer">
            <img src="google.png" alt="Google" className="w-6 h-6 mx-auto my-auto" />
          </button>
          <button id="facebook-button" className="border-2 border-gray-300 rounded-full w-12 h-12 bg-gray-50 py-3 cursor-pointer">
            <img src="facebook.png" alt="Facebook" className="w-6 h-6 mx-auto my-auto" />
          </button>
          <button id="twitter-button" className="border-2 border-gray-300 rounded-full w-12 h-12 bg-gray-50 y-3 cursor-pointer ">
            <img src="twitter.png" alt="Twitter" className="w-6 h-6 mx-auto my-auto" />
          </button>
        </div>
        <p className="text-center my-6">Eres nuevo? <Link to="/register" className="text-blue-500 font-semibold">Regístrate</Link></p>
      </div>
    </div>

  );
};

export default Login;
