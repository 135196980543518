import React from 'react';

const Profile = ({ user }) => {
  return (
    <div className="user-profile">
      <div className="user-avatar">
        
      </div>
      <div className="user-details">
        
        <ul>
          <li>
            <strong>Seguidores:</strong>
          </li>
          <li>
            <strong>Siguiendo:</strong> 
          </li>
          <li>
            <strong>Publicaciones:</strong>
          </li>
        </ul>
      </div>
      <div className="user-actions">
        <button>Seguir</button>
      </div>
    </div>
  );
};

export default Profile;
