import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { FaSearch, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, auth } from '../../firebase';

const App = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [etiquetasOpen, setEtiquetasOpen] = useState(false);
  const [recetasFiltradas, setRecetasFiltradas] = useState([]); // Estado para las recetas filtradas
  const [user, setUser] = useState(null);
  const [recetaSeleccionada, setRecetaSeleccionada] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    const results = value
      ? categorias.filter((categoria) =>
        categoria.nombre.toLowerCase().includes(value.toLowerCase())
      )
      : [];

    setSearchResults(results);
  };

  const handleAddCategory = (categoria) => {
    if (!selectedCategories.includes(categoria)) {
      setSelectedCategories([...selectedCategories, categoria]);
    }
  };

  const handleRemoveCategory = (categoria) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat !== categoria));
  };

  const handleFilterRecetas = useCallback(() => {
    if (selectedCategories.length === 0) {
      setRecetasFiltradas(recetas);
    } else {
      const filteredRecetas = recetas.filter((receta) => {
        return selectedCategories.every((selectedCategoria) =>
          receta.categorias.includes(selectedCategoria)
        );
      });
      setRecetasFiltradas(filteredRecetas);
    }
  }, [recetas, selectedCategories]);


  const fetchRecetas = async () => {
    const db = getFirestore();
    const recetasCol = collection(db, 'recetas');

    try {
      const recetasSnapshot = await getDocs(recetasCol);
      const recetasData = recetasSnapshot.docs.map((doc) => doc.data());
      setRecetas(recetasData);
      setRecetasFiltradas(recetasData); // Mostrar todas las recetas inicialmente
    } catch (error) {
      console.error('Error al obtener recetas:', error);
    }
  };

  const handleToggleEtiquetas = () => {
    setEtiquetasOpen(!etiquetasOpen);
  };

  const fetchEtiquetas = async () => {
    const db = getFirestore();
    const etiquetasCol = collection(db, 'etiquetas');

    try {
      const etiquetasSnapshot = await getDocs(etiquetasCol);
      const etiquetasData = etiquetasSnapshot.docs.map((doc) => doc.data());
      setCategorias(etiquetasData);
    } catch (error) {
      console.error('Error al obtener etiquetas:', error);
    }
  };
  const checkUserRole = (user) => {
    const db = getFirestore();
    if (user) {
      console.log('Usuario logueado:', user.uid);
      const userDocRef = doc(db, 'usuarios', user.uid);
      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            
            if (userData.role === 'Administrador') {
              setIsAdmin(true);
             

            } else {
              setIsAdmin(false);
            }
          }
        })  
        .catch((error) => {
          console.error('Error al consultar el documento de usuario:', error);
          setIsAdmin(false);
        });
    }
  };


  useEffect(() => {
    fetchRecetas();
    fetchEtiquetas();
  }, []);

  useEffect(() => {
    handleFilterRecetas();
  }, [handleFilterRecetas, selectedCategories]);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, (usuario) => {
      if (usuario) {
        setUser(usuario);
        
        checkUserRole(usuario); // Verifica el rol del usuario al iniciar sesión

      } else {
        setUser(null);
      }
    });
  }, []);


  return (
    <div className="">
      <header className='fixed top-0 left-0 w-full z-20'>
        <nav className="bg-blue-500 text-white flex justify-between items-center p-4 ">
          <h1 className="text-white">Recetas del Chef</h1>

          {user ? (

            <div className="relative">
              <button className="flex items-center focus:outline-none" onClick={toggleDropdown}>
                <img src='https://firebasestorage.googleapis.com/v0/b/menuvirtual-b639f.appspot.com/o/images%2Ficonuser.png?alt=media&token=dcb71d90-8c96-46f9-9b4f-45e2ada2e6e7&_gl=1*ocd880*_ga*MTg5NjkxNDY4NC4xNjk1MTEwOTE5*_ga_CW55HF8NVT*MTY5ODczOTk2OS40Ny4xLjE2OTg3NDAyNjEuNDYuMC4w' alt="User Icon" className="w-12 h-12" />
              </button>
              {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-lg">
                  <ul className="py-2">
                  <li>
                      <Link to="/profile" ><a href="#" className="block px-4 py-2 hover-bg-gray-200 text-black">Perfil</a></Link>
                    </li>
                    <li>
                      <Link to="/suggestions" ><a href="#" className="block px-4 py-2 hover-bg-gray-200 text-black">Sugerencias</a></Link>
                    </li>
                    <li>
                      {isAdmin && (
                        <Link to="/recetas" ><a href="#" className="block px-4 py-2 hover:bg-gray-200 text-black">Añadir Receta</a></Link>
                      )}
                    </li>

                    <li>
                      {isAdmin && (
                        <Link to="/etiquetas" > <a href="#" className="block px-4 py-2 hover:bg-gray-200 text-black">Añadir Etiqueta</a></Link>
                      )}
                    </li>
                    <li>
                    {isAdmin && (
                    <Link to="/checksuggestions" ><a href="#" className="block px-4 py-2 hover-bg-gray-200 text-black">Ver Sugerencias</a> </Link>
                    )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <Link to="/login">
              <button className="bg-orange-500 hover:bg-red-500 text-white border-none p-4 rounded-md text-sm cursor-pointer ml-4">Iniciar Sesión</button>
            </Link>
          )}

        </nav>
      </header>
      <div className="flex ">


        <section className='mt-24 mr-8 w-[68%] ml-10 z-0 box-border border-2 border-solid rounded-2xl p-4'>
          <h2 className="mb-7 font-bold text-4xl">Recetas Destacadas</h2>
          {recetasFiltradas.map((receta, index) => (
            <div className="border p-4 flex flex-col gap-4 mb-4 rounded-2xl " key={index}>
              {recetaSeleccionada === receta ? (
                <div className="mt-4">
                  <div className="text-center text-2xl font-bold mb-2">
                    {receta.titulo}
                  </div>
                  <img src={receta.imagen} alt={`Receta de ${receta.titulo}`} className="w-full h-[400px] object-cover rounded-md mb-2" />
                  <div className='flex'>
                    <img className='h-8 w-8' alt='Personas' src='https://firebasestorage.googleapis.com/v0/b/menuvirtual-b639f.appspot.com/o/images%2Fpersonita.png?alt=media&token=4bfaf10d-7fd5-4138-8e59-720ae96b5de3&_gl=1*1isffv4*_ga*NzYxMjczMDk0LjE2OTQ5ODkxMDQ.*_ga_CW55HF8NVT*MTY5ODcxMjk2My4xMi4xLjE2OTg3MTI5NzAuNTMuMC.4w' />
                    <p>: {receta.cantidadPersonas}</p>
                  </div>
                  <div>
                    <h4 className="text-blue-500">Ingredientes</h4>
                    <ul className="list-decimal pl-4">
                      {receta && receta.ingredientes && receta.ingredientes.map((ingrediente, ingIndex) => (
                        <li key={ingIndex}>{ingrediente}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-blue-500">Elaboración</h4>
                    <div className="mb-8">
                      <ol className="list-decimal pl-4">
                        {receta.elaboracion.map((paso, pasoIndex) => (
                          <li key={pasoIndex}>{paso}</li>
                        ))}
                      </ol>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-3 ml-10 mt-2 w-[60%-10px]">
                    {receta.categorias.map((categoria, catIndex) => (
                      <div className="flex items-center gap-1 transition-[background-color 0.3s] bg-blue-500 text-white p-2 rounded-[20px] mr-2 mb-2 text-sm" key={catIndex}>
                        {categoria}
                      </div>
                    ))}
                  </div>
                  <div>
                    <button className="bg-green-500 text-white px-4 py-2 rounded-md mt-2 mr-3">Ver Receta</button>
                    <button
                      onClick={() => setRecetaSeleccionada(null)} // Cerrar detalles
                      className="bg-orange-400 text-white text-center cursor-pointer px-4 py-2 rounded-md mt-2"
                    >
                      Cerrar Detalles
                    </button>
                  </div>
                </div>
              ) : (
                <div className=" p-4 flex gap-4  " key={index}>
                  <div>
                    <img src={receta.imagen} alt={`Receta de ${receta.titulo}`} className="w-44 h-44 rounded-md" />
                  </div>
                  <div className="flex-1">
                    <div className="flex justify-between items-center mb-2">
                      <h2 className="text-2xl font-bold">{receta.titulo}</h2>
                      <button
                        onClick={() => setRecetaSeleccionada(receta)} // Abrir detalles
                        className="text-blue-500 text-center cursor-pointer"
                      >
                        Abrir Detalles
                      </button>
                    </div>
                    <p>{receta.descripcion}</p>
                    <div className='flex mt-2'>
                      <img className='h-8 w-8' alt='Personas' src='https://firebasestorage.googleapis.com/v0/b/menuvirtual-b639f.appspot.com/o/images%2Fpersonita.png?alt=media&token=4bfaf10d-7fd5-4138-8e59-720ae96b5de3&_gl=1*1isffv4*_ga*NzYxMjczMDk0LjE2OTQ5ODkxMDQ.*_ga_CW55HF8NVT*MTY5ODcxMjk2My4xMi4xLjE2OTg3MTI5NzAuNTMuMC.4w' />
                      <p>: {receta.cantidadPersonas}</p>
                    </div>
                    <div className="flex flex-wrap gap-3 mt-4 w-[60%-10px]">
                      {receta.categorias.map((categoria, catIndex) => (
                        <div className="flex items-center gap-1 transition-[background-color 0.3s] bg-blue-500 text-white p-2 rounded-[20px] mr-2 mb-2 text-sm" key={catIndex}>
                          {categoria}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </section>
        <section className="mt-24 mr-[17%] w-80  fixed right-[-100px] ">
          <div className='box-border relative border-2 border-solid rounded-2xl p-4 w-[500px]'>
            <div className='box-border relative border-2 border-solid rounded-2xl p-4'>
              <h2> Buscar Categorías</h2>
              <div className=" mt-4 w-96 flex items-center justify-left ">
                <input
                  type="text"
                  placeholder="Buscar categorías"
                  value={searchValue}
                  onChange={handleSearch}
                  className="p-2 border border-gray-300 rounded-md w-full"
                />
                <FaSearch
                  className="ml-[-30px] text-base text-gray-600 cursor-pointer"

                />
              </div>

              <div className="  mt-2 grid grid-cols-[repeat(4,1fr)] gap-2 grid-rows-[auto,auto] overflow-auto">
                {searchResults.map((result) => (
                  <div
                    key={result.id}
                    className={` ${selectedCategories.includes(result.nombre) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} p-2 rounded-2xl cursor-pointer flex items-center gap-2 transition duration-300 hover:bg-gray-400`}
                    onClick={() => handleAddCategory(result.nombre)}
                  >
                    {result.nombre}
                    {selectedCategories.includes(result.nombre) && (
                      <FaTimes
                        className=" ml-auto text-base text-white cursor-pointer"
                        onClick={() => handleRemoveCategory(result.nombre)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=" mt-4 box-border relative border-2 border-solid rounded-2xl p-4">

              <div onClick={handleToggleEtiquetas} className="cursor-pointer flex gap-2 items-center">
                {etiquetasOpen ? (
                  <>
                    Todas las Categorías <FaChevronUp />
                  </>
                ) : (
                  <>
                    Todas las Categorías <FaChevronDown />
                  </>
                )}
              </div>
              {etiquetasOpen && (
                <div className=" grid grid-cols-[repeat(4,1fr)] gap-2 mt-2 mb-2 grid-rows-[auto,auto] max-h-96 max-w-96 overflow-auto">
                  {categorias.map((etiqueta) => (
                    <div
                      key={etiqueta.id}
                      className={` ${selectedCategories.includes(etiqueta.nombre) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}  p-2 rounded-2xl cursor-pointer flex items-center gap-2 transition duration-300 hover:bg-gray-400`}
                      onClick={() => handleAddCategory(etiqueta.nombre)}
                    >
                      {etiqueta.nombre}
                      {selectedCategories.includes(etiqueta.nombre) && (
                        <FaTimes
                          className=" ml-auto text-base text-white cursor-pointer"
                          onClick={() => handleRemoveCategory(etiqueta.nombre)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

          </div>
        </section>
      </div>
      <footer className="bg-gray-800 text-white p-4 h-28 mt-8">
        <div className="container mx-auto flex flex-wrap items-center justify-between h-full">
          <div className="w-full md:w-1/2 text-center md:text-left">
            <p>&copy; 2023 RecetaChef</p>
          </div>
          <div className="w-full md:w-1/2 text-center md:text-right flex items-end">
            <p className="mr-4">About</p>
            <p href="#" className="mr-4">Privacy Policy</p>
            <p href="#" className="mr-4">Licensing</p>
            <p href="#">Contact</p>
          </div>
        </div>
      </footer>

    </div>

  );
};

export default App;
