import { Routes, Route } from "react-router-dom"
import Inicio from "./pages/home/inicio"
import Login from "./pages/login/login"
import FormularioReceta from "./forms/recetas"
import Etiquetas from "./forms/etiquetas"
import Register from "./pages/register/register"
import Profile from "./pages/profile/profile"
import FormularioSuggestions from "./pages/suggestions/suggestions"
import CheckSuggestions from "./pages/checksuggestions/checksuggestions"


function Aplicacion() {
  return (
    <div className="Aplicacion">
      <Routes>
        <Route path="/" element={ <Inicio /> } />
        <Route path="login" element={ < Login/> } />
        <Route path="register" element={ < Register/> } />
        <Route path="recetas" element={ <FormularioReceta/>}/>
        <Route path="etiquetas" element={ <Etiquetas/>}/>
        <Route path="suggestions" element={ <FormularioSuggestions/>}/>
        <Route path="profile" element={ <Profile/>}/>
        <Route path="checksuggestions" element={ <CheckSuggestions/>}/>
      </Routes>
    </div>
  )
}

export default Aplicacion