import React, { useState, useEffect } from 'react';
import { getFirestore, getDocs, collection, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { app } from '../firebase';


const Etiquetas = () => {
  const [categorias, setCategorias] = useState([]);
  const [tag, setTag] = useState('');
  const [db, setDb] = useState(null);

  useEffect(() => {
    const db = getFirestore(app);
    setDb(db);

    const fetchCategorias = async () => {
      try {
        const categoriasSnapshot = await getDocs(collection(db, 'etiquetas'));
        const categoriasData = categoriasSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setCategorias(categoriasData);
      } catch (error) {
        console.error('Error al obtener categorías:', error);
      }
    };

    fetchCategorias();
  }, [setDb, setCategorias]);

  const handleAddTag = async () => {
    if (tag.trim() !== '') {
      const etiquetaData = {
        nombre: tag,
      };
  
      try {
        // Usar el nombre del tag como UID del documento
        const docRef = doc(db, 'etiquetas', tag);
  
        // Establecer los datos de la etiqueta en el documento
        await setDoc(docRef, etiquetaData);
  
        // Actualizar la lista de etiquetas
        setCategorias([...categorias, { id: tag, ...etiquetaData }]);
        setTag('');
      } catch (error) {
        console.error('Error al agregar etiqueta:', error);
      }
    }
  };

  const handleRemoveTag = async (tagId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta etiqueta?')) {
      try {
        // Buscar el nombre del documento por el nombre de la etiqueta
        const tagName = categorias.find((categoria) => categoria.id === tagId)?.nombre;
  
        if (tagName) {
          // Eliminar la etiqueta de la base de datos usando el nombre del documento
          await deleteDoc(doc(db, 'etiquetas', tagName));
          console.log('Etiqueta eliminada de Firestore con nombre: ', tagName);
        }
  
        // Actualizar la lista de etiquetas
        setCategorias(categorias.filter((categoria) => categoria.id !== tagId));
      } catch (error) {
        console.error('Error al eliminar etiqueta:', error);
      }
    }
  };

  return (
    <div className="w-[300px] my-auto p-5 border border-solid rounded-md bg-white mx-auto">
      <h2 className='font-md'>Etiquetas</h2>
      <div className="flex mb-5">
        <input
        className='flex-1 p-1 border border-solid rounded-md'
          type="text"
          placeholder="Agregar etiqueta"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
        <button onClick={handleAddTag} className='p-1 py-2 bg-blue-500 text-white border-none rounded-md cursor-pointer'>Agregar</button>
      </div>
      <ul className='list-none p-0'>
        {categorias.map((categoria) => (
          <ul key={categoria.id}>
            <div className='border-2 border-solid rounded-md mb-2 flex justify-between items-center p-1'>
            {categoria.nombre+' '}
            <button onClick={() => handleRemoveTag(categoria.id)} className='bg-red-500 text-white border-none rounded-md cursor-pointer hover: bg-orange-500'>Eliminar</button>
            </div>
          </ul>
        ))}
      </ul>
    </div>
  );
};

export default Etiquetas;
